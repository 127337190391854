body {
  margin: 0;
  background-color: #FAFAFA;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.psBtn {
  height: 64px;
  color: #5D4AEE;
  border: 2px solid #5D4AEE;
  border-radius: 6px;
  width: 100%;
  background-color: #FFFFFF;
  padding: 16px;
  font-size: 16px;
  font-weight: 600;
}

.loadingPsBtn {
  opacity: 0.3;
  font-size: 12px !important;
}
